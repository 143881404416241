<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item><i class="fa fa-home"></i> 首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">运营中心</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">帮助文档文档</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>
			<div class="wf mgtop10">
				<el-form inline>
					<el-form-item label="帮助类别">
						<el-select size="small" placeholder="请填写帮助类别名称" v-model="searchForm.name">
							<el-option v-for="(item,index) in protocolTypeList" :key="index" :label="item.name"
								:value="item.serial"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="名称">
						<el-input size="small" placeholder="请填写协议名称" v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item label="名称">
						<el-input size="small" placeholder="请填写协议名称" v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="success" size="small" @click="doSearch"><i class="fa fa-search"></i> 查询
						</el-button>
					</el-form-item>
					<el-form-item>
						<a href="/m/db/help/classification">
							<el-button size="small" type="primary" style="margin-bottom: 16px" icon="menu-fold"><i
									class="fa fa-plus"></i>新增帮助类别</el-button>
						</a>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="frame_content mgtop10" style="min-height: 600px;">

			<div class="wf lineh40">
				<el-button v-if="principal.level==3" type="danger" size="small" @click="batchRemove"><i class="fa fa-trash"></i> 批量删除</el-button>
				<el-button type="success" size="small" @click="newItem"><i class="fa fa-plus"></i>新增文档</el-button>
				<el-button type="success" size="small"><i class="fa fa-download"></i> 批量导出</el-button>
			</div>

			<el-table class="mgtop10" :data="helpList" :stripe="true" border @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="名称" prop="name">
					<template slot-scope="scope">
						{{scope.row.name}}<span class="mgl10">
							<el-tag v-if="scope.row.recommend==1">推荐</el-tag>
						</span>
					</template>
				</el-table-column>
				<el-table-column label="分类" prop="classificationName" width="200px" />
				<el-table-column label="发布时间" prop="subTime" align="center" width="160px" />
				<el-table-column label="操作" key="action" align="center" width="200px">
					<template slot-scope="scope">
						<el-row>
							<el-col span="24">
								<el-button class="wf" type="success" size="small" @click="recommendHelp(scope.$index)">
									推荐</el-button>
							</el-col>
							<el-col span="12" class="mgtop10">
								<el-button class="w80" type="primary" size="small" @click="showHelp(scope.$index)">编辑
								</el-button>
							</el-col>
							<el-col v-if="principal.level==3" span="12" class="mgtop10">
								<el-button class="w80" type="danger" size="small" @click="removeHelp(scope.$index)">删除
								</el-button>
							</el-col>
						</el-row>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf overflow bgffffff mgtop10">
				<div class="pdding20">
					<el-pagination v-show="helpList.length>0" background layout="prev, pager, next"
						@current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total"
						class="fr"> </el-pagination>
				</div>
			</div>
		</div>


		<!-- 弹出层 -->
		<fragment-help-option ref="helpOption" @addHelp="addHelp"></fragment-help-option>
		<!-- 弹出层 end-->

	</div>
</template>

<script>
	import VueUEditor from 'vue-ueditor'
	import FragmentHelpOption from '@/components/fragment/news/FragmentHelpOption.vue'

	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		components: {
			VueUEditor,
			FragmentHelpOption
		},
		data() {
			return {
				imgUploadUrl: this.$http.baseUploadUrl,
				uploadHeaders: {
					'Authorization': sessionStorage.getItem("token")
				},
				modalMask: false,
				modalzIndex: -1,
				tableLocale: {
					emptyText: '暂无数据'
				},
				dialogFormVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				multipleSelection: [],
				helpTypeList: [],
				helpClassificationList: [],
				helpClassificationCacheList: [],
				helpList: [],
				mcover: '',
				helpForm: {},
				loading: false,
				imageUrl: '',
			}
		},
		beforeCreate() {},
		mounted() {},
		methods: {
			addHelp(data) {
				this.helpList.unshift(data);
			},
			ueditorReady(ueditor) {
				let _this = this
				console.log("编辑器", this.ueditor)
				ueditor.addListener("contentChange", () => {
					console.log("文档内容", ueditor.getContent())
					_this.helpForm.content = ueditor.getContent();
				});
				ueditor.setContent(this.helpForm.content)
			},
			dateChange(moment, dateString) {

			},
			menuSelect(item) {
				console.log("菜单" + item.key, item);
				//获取当前类别的分类
				this.curTypeName = this.helpTypeList[parseInt(item)].name;
				this.curTypeSerial = this.helpTypeList[parseInt(item)].serial;
				this.searchForm.page = 1;
				this.searchForm.superSerial = this.curTypeSerial;
				this.searchForm.classification = this.curTypeSerial;
				this.searchForm.name = "";
				this.getClassificationList();
				this.getHelpList();
			},
			uploadChange({
				file
			}) {
				console.log("文件上传进度", file);
				this.mcover = "";
				if (file.status == "done") { //上传完成
					this.loading = false;
					this.requireMcover = false;
					this.mcover = file.response.files[0].uploadUrl
				}

				if (file.status == "uploading") { //上传完成
					this.loading = true;
				}

			},
			newItem() {
				this.$refs["helpOption"].newItem();
			},
			showHelp(index) {
				this.helpForm = this.helpList[index];
				this.$refs["helpOption"].showItem(this.helpForm);
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getHelpList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getHelpList();
			},
			getHelpTypeCacheList() { //从缓存获取项目类别
				this.$http.request(this, this.$apis.material.helpTypeCacheList, {}, false, res => {
					let data = res.data;
					0
					this.helpTypeList = data;
					console.log("项目类别已加载完", this.helpTypeList);
					//获取当前类别的分类
					this.curTypeName = this.helpTypeList[0].name;
					this.curTypeSerial = this.helpTypeList[0].serial;
					this.searchForm.superSerial = this.curTypeSerial;
					this.getClassificationList();
				});
			},
			getClassificationList() {
				this.$http.request(this, this.$apis.material.helpClassificationList, this.searchForm, false, res => {
					this.helpClassificationList = res.data;
					this.total = res.count;
				});
			},
			getHelpList() {
				this.$http.request(this, this.$apis.material.helpList, this.searchForm, false, res => {
					this.helpList = res.data;
					this.total = res.count;
				});
			},
			recommendHelp(index) {
				var that = this;
				var help = this.helpList[index];
				this.$confirm('确定要删除帮助文档[' + help.name + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.helpRecommend.replace("{serial}", help.serial);
					this.$http.request(this, url, {}, true, res => {
						help.recommend = data;
					});
				});
			},
			removeHelp(index) {
				console.log(index, this.helpList[index])
				var that = this;
				var help = this.helpList[index];
				this.$confirm('确定要删除帮助文档[' + help.name + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.helpRemove.replace("{serial}", help.serial);
					this.$http.request(this, url, {}, true, res => {
						this.helpList.splice(index, 1);
					});
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			batchRemove() {
				var serials = "";
				this.multipleSelection.filter(item => {
					serials += (item.serial + ",");
				});
				serials = serials.length == 0 ? "" : serials.substring(0, serials.length - 1);

				this.$confirm('此操作将永久删除选中的帮助文档 , 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.request(this, this.$apis.material.helpBatchRemove, {
						serials: serials
					}, true, res => {
						this.helpList = this.helpList.filter(help => {
							let flag = true;
							this.multipleSelection.filter(select => {
								if (select.serial == help.serial) {
									flag = false;
								}
							})
							return flag;
						});

					});
				});
			},
		},
		created() {
			this.getHelpTypeCacheList();
			this.getHelpList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
	.edui-editor div {
		line-height: 20px;
	}

	.edui-default .edui-dialog {
		z-index: 9999 !important;
	}

	#edui_fixedlayer {
		z-index: 9999 !important;
	}
</style>
